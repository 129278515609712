<template>
  <v-card
    height="100%"
    max-height="150px"
    style="font-size: .75em; line-height: initial !important;"
    class="flexcard clickable"
    :class="{sponsored: sponsored, 'elevation-3': !sponsored, shakeMe: sponsored, unpublished: !event.isPublic}"
    @click.native="goto"
  >
    <v-overlay :value="true" v-if="this.event.statusId > 100" color="rgba(0,0,0,0.7)" absolute z-index="4">
      <div class="d-flex justify-center align-center text-center">

        <span class="text-h3 pa-3 overlayText">{{isCanceled ? 'Canceled' : 'Postponed'}}</span>
      </div>
    </v-overlay>
    <div
      v-else
      class="pa-0 d-flex justify-space-between"
      style="position: absolute; width: 100%;z-index: 4;"
    >
      <v-spacer></v-spacer>
      <v-btn
        text icon
        @click.stop="bookMe"
        :loading="booking"
        small
      >
        <v-icon>
          fa{{bookmarked ? 's' : 'r'}} fa-heart
        </v-icon>
      </v-btn>
    </div>
    <!-- Normal -->
    <template v-if="!image">
      <v-card-text class="grow t-card pb-0">
        <v-row dense>
          <v-col style="width: 100px; max-width: 100px">
            <template  v-if="!multiDay">
              <div>{{event.startDate.format('dddd')}}</div>
              <div class="font-weight-bold">{{event.startDate.format('MMM Do')}}</div>
            </template>
            <template v-else>
              <div v-if="event.eventType !== 'Youth Program'">{{event.startDate.format('ddd')}} - {{event.endDate.format('ddd')}}</div>
              <div class="font-weight-bold">{{event.startDate.format('MMM Do')}} - {{event.sameMonth ? event.endDate.format('Do') : event.endDate.format('MMM Do')}}</div>
            </template>
            <div v-if="year">{{year}}</div>
          </v-col>
          <v-col class="px-0" style="max-height: 55px; overflow: hidden;">
            <template>
              <div class="font-weight-bold">{{event.name}}</div>
              <div>{{event.divisionNames | formatArray}}</div>
            </template>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="align-end pt-0 t-card">
        <v-row dense>
          <v-col cols="5">
            <div style="width:100%; height: 60px">
              <v-carousel
                :show-arrows="false"
                hide-delimiters
                height="60"
                max="60"
                class="elevation-0"
                cycle continuous
                :interval="interval"
                @change="changes++"
              >
                <v-carousel-item
                  height="60px"
                  max-height="60px"
                  v-for="(logo, i) in logos"
                  :key="`logo_${i}`"
                  :class="logo.class"
                  :style="logo.style"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                >
                  <img :src="logo.url" style="max-width: 100%; max-height: 100%;">
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-col>
          <v-col class="d-flex justify-end align-center" cols="7" >
            <div class="font-weight-bold">{{event.locationsString}}</div>
          </v-col>
        </v-row>
        <v-row dense v-if="sponsored">
          <v-col class="grey--text" cols="12" >
            Sponsored
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="false">
        <v-spacer></v-spacer>
        <!-- BOOKMARK -->
        <v-btn color="color3--text" icon v-if="false">
          <v-icon>far fa-heart</v-icon>
        </v-btn>
        <!-- SHARE -->
        <v-btn color="color3--text" icon v-if="false">
          <v-icon>fas fa-share-alt</v-icon>
        </v-btn>
        <!-- VIEW -->
        <v-btn
          color="color3 color3Text--text"
          fab
          small
          @click.stop="goto"
        >
          <v-icon>fas fa-caret-right</v-icon>
        </v-btn>
      </v-card-actions>
    </template>
    <!-- Tournament Image -->
    <v-container fluid v-else class="pa-0">
      <v-row dense>
        <v-col cols="4" class="pa-4 d-flex">
          <v-row dense>
            <v-col cols="12">
              <template  v-if="!multiDay">
                <div>{{event.startDate.format('dddd')}}</div>
                <div class="font-weight-bold">{{event.startDate.format('MMM Do')}}</div>
              </template>
              <template v-else>
                <div>{{event.startDate.format('ddd')}} - {{event.endDate.format('ddd')}}</div>
                <div class="font-weight-bold">{{event.startDate.format('MMM Do')}} - {{event.endDate.format('Do')}}</div>
              </template>
            </v-col>
            <v-col class="d-flex align-end" cols="12" >
              <div style="width:100%; height: 60px">
                <v-carousel
                  :show-arrows="false"
                  hide-delimiters
                  height="60"
                  max="60"
                  class="elevation-0"
                  cycle continuous interval="3000"
                >
                  <v-carousel-item
                    height="60px"
                    max-height="60px"
                    v-for="(logo, i) in logos"
                    :key="`logo_${i}`"
                    :src="logo.url"
                    :class="logo.class"
                    contain
                  ></v-carousel-item>
                </v-carousel>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="8" class="pa-2">
          <v-img
            :src="image"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
    <div class="text-center eType color3 color3Text--text">
      <v-chip
        small
        v-if="event.tags && event.tags.includes('Double Points')"
        color="success white--text"
        class="mt-n8"
        style="position: absolute"
      >2X Points</v-chip>
      <v-chip
        small
        v-else-if="event.tags && event.tags.includes('Bonus Points')"
        color="success white--text"
        class="mt-n8"
        style="position: absolute"
      >1.5X Points</v-chip>
      {{event.manualEventType || event.eventType}}
      <span v-if="!event.isSeries && admin && showCounts">({{ event.teamCount || 0 }} Registrations)</span>
    </div>
  </v-card>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  props: ['event', 'toolbar', 'sponsored', 'image', 'emitter', 'showCounts'],
  data () {
    return {
      changes: 0,
      booking: false
    }
  },
  computed: {
    ...mapGetters(['user', 'publicView', 'inIFrame']),
    bookmarked () {
      return this.user && this.user.bookmarks && this.user.bookmarks.find(f => f.id === this.event.id && f.type === 'tournament')
    },
    interval () {
      return this.changes > this.logos.length ? 6000 : 3000
    },
    admin () {
      return this.user && this.user.isOrganizationAdmin(this.event.organization.id)
    },
    multiDay () {
      return this.event && !this.event.startDate.isSame(this.event.endDate)
    },
    days () {
      return moment.duration(this.event.startDate.diff(this.event.endDate)).days
    },
    dateParts () {
      return {
        day: {
          start: this.event.startDate.day(),
          end: this.event.endDate.day()
        },
        month: {
          start: this.event.startDate.month(),
          end: this.event.endDate.month()
        },
        year: {
          start: this.event.startDate.year(),
          end: this.event.endDate.year()
        }
      }
    },
    year () {
      const y = this.event && this.event.startDate.year()
      return y && moment().year() === y ? false : this.event.startDate.year()
    },
    eventRouterLink () {
      return this.event.isSeries ? {
        name: 'series-home',
        params: { seriesTag: this.event.urlTag }
      } : this.event.isLeague ? {
        name: 'league-brochure',
        params: { tournamentId: this.event.id }
      } : this.event.isTrainingSession ? {
        name: 'session-brochure',
        params: { tournamentId: this.event.id }
      } : {
        name: 'tournament-brochure',
        params: { tournamentId: this.event.id }
      }
    },
    sanctionImage () {
      if (this.event && this.event.sanctionedBy === 'AAU') return 'https://vblimages.blob.core.windows.net/images/AAU.png'
      if (this.event && this.event.sanctionedBy === 'AVP Next') return 'https://vblimages.blob.core.windows.net/images/avpnext_icon.png'
      if (this.event && this.event.sanctionedBy.startsWith('AVP')) return 'https://vblimages.blob.core.windows.net/images/avpfirstlogo.png'
      return null
    },
    logos () {
      return this.event.logos
    },
    eventDivisionNames () {
      return this.event.divisions && this.event.divisions.map(d => d.name)
    },
    isCanceled () {
      return this.event.statusId === 101
    }
  },
  methods: {
    goto () {
      if (this.emitter) {
        this.$emit('event-clicked', this.event.id)
        return
      }
      if (this.inIFrame) {
        const routeData = this.$router.resolve(this.eventRouterLink)
        window.open(routeData.href, '_blank')
        return
      }
      this.$router.push(this.eventRouterLink)
    },
    bookMe () {
      if (!this.user) {
        this.$emit('blocked', 'You must be logged in to favorite a tournament')
        return
      }
      this.booking = true
      this.$VBL.user.toggleBookmark('tournament', this.event.id)
        .catch(e => console.log(e.response))
        .finally(() => { this.booking = false })
    }
  }
}
</script>

<style scoped>
.t-card {
  font-size: 10.75px !important;
  line-height: inherit !important;
}
.eType {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.sponsored {
  box-shadow: 0px 7px 8px -4px rgba(24, 158, 187, 0.2), 0px 13px 19px 2px rgba(24, 158, 187, 0.14), 0px 5px 34px 4px rgba(24, 158, 187, 0.12) !important;
}
.shakeMe {
  animation-name: shake;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}
@keyframes shake {
  0% {left: 0}
  1% {left: -3px}
  2% {left: 5px}
  3% {left: -8px}
  4% {left: 8px}
  5% {left: -5px}
  6% {left: 3px}
  7% {left: 0}
}
.unpublished {
  box-shadow: 0px 3px 3px -2px rgba(255,0,0,0.2), 0px 3px 4px 0px rgba(255,0,0,0.14), 0px 1px 8px 0px rgba(255,0,0,0.12) !important;
}

</style>
