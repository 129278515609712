import Vue from 'vue'
import * as mutations from '@/store/MutationTypes'
import * as actions from '@/store/ActionTypes'
import isDark from '@/classes/IsDark'
import LogoUrls from '@/classes/LogoUrls'
import vuetify from '@/plugins/vuetify'

export default {
  state: {
    theme: null,
    base: {
      username: 'vbl',
      name: 'Volleyball Life',
      logoUrl: null,
      welcomeText: 'Welcome to Volleyball Life',
      primary: '#152a69',
      secondary: '#446da3',
      accent: '#189ebb',
      error: '#FF5252',
      info: '#2196F3',
      success: '#4CAF50',
      warning: '#FFC107',
      color1: '#152a69',
      color1Text: '#ffffff',
      color2: '#446da3',
      color2Text: '#ffffff',
      color3: '#189ebb',
      color3Text: '#ffffff',
      color4: '#92becb',
      color4Text: '#000000',
      color5: '#e1dad0',
      color5Text: '#000000',
      color6: '#fffced',
      color6Text: '#000000',
      avpYellow1: '#fcd538',
      avpYellow2: '#ffd400',
      isPreview: false,
      pointSystems: [],
      rankings: [],
      pointsOnly: false,
      props: [],
      jsonProps: null,
      dark: false,
      drawer: true
    },
    themes: []
  },
  mutations: {
    [mutations.STORE_THEME] (state, payload) {
      if (payload) {
        // Remove null props
        for (var propName in payload) {
          if (propName !== 'logoUrl' && (payload[propName] === null || payload[propName] === undefined)) {
            delete payload[propName]
          }
        }
        const i = state.themes.findIndex(f => {
          return f.username === payload.username
        })
        const existing = i > -1 ? state.themes[i] : {}
        // Merge with defaults and existing
        const theme = Object.assign({}, state.base, existing, payload)
        theme.primary = theme.color1
        theme.secondary = theme.color2
        theme.accent = theme.color3

        if (i > -1) {
          state.themes.splice(i, 1, theme)
        } else {
          state.themes.push(theme)
        }
      }
    },
    [mutations.APPLY_THEME] (state, payload) {
      if (typeof payload === 'string') {
        payload = state.themes.find(f => {
          return f.username === payload
        })
      }
      state.theme = Object.assign({}, state.base, payload)

      // Clone object and remove non-colors
      const colors = Object.assign({}, state.theme)
      delete colors.altHome
      delete colors.dark
      delete colors.darkFooterLogoUrl
      delete colors.darkToolbarLogoUrl
      delete colors.darkToolbarLogoXSUrl
      delete colors.darkTournamentLogoUrl
      delete colors.drawer
      delete colors.email
      delete colors.excludes
      delete colors.footerLogoUrl
      delete colors.isPreview
      delete colors.jsonProps
      delete colors.logoUrl
      delete colors.membership
      delete colors.metaLogoUrl
      delete colors.name
      delete colors.pointSystems
      delete colors.pointsOnly
      delete colors.props
      delete colors.rankings
      delete colors.ratingSystems
      delete colors.ratingsOnly
      delete colors.toolbarLogoUrl
      delete colors.toolbarLogoXSUrl
      delete colors.tournamentLogoUrl
      delete colors.username
      delete colors.welcomeText

      delete colors.loadError

      Object.assign(vuetify.framework.theme.themes.light, colors)
      Object.assign(vuetify.framework.theme.themes.dark, colors)
    },
    [mutations.SET_THEME_DARK] (state, payload) {
      state.theme.dark = payload
      vuetify.framework.theme.dark = payload
    },
    [mutations.SET_THEME_DRAWER] (state, payload) {
      state.theme.drawer = payload
    }
  },
  actions: {
    [actions.UPDATE_THEME] ({ commit, state, getters }, payload) {
      if (payload) {
        commit(mutations.STORE_THEME, payload)
        const username = payload.username
        if (state.theme.username === username) {
          const theme = getters.getTheme(username)
          theme.isPreview = state.theme.isPreview
          commit(mutations.APPLY_THEME, theme)
        }
      }
    },
    [actions.LOAD_THEME] ({ commit, state }, payload) {
      if (!payload || !payload.username) {
        payload = { username: payload }
      }
      return new Promise((resolve, reject) => {
        if (!payload.force) {
          const t = state.themes.find(f => {
            return f.username === payload.username
          })
          if (t) return resolve(payload.username)
        }

        const sdk = Vue.prototype.$VBL
        sdk.theme.get(payload.username)
          .then(response => {
            commit(mutations.STORE_THEME, response.data)
            return resolve(response.data.username)
          })
          .catch(error => {
            console.log(error.response)
            commit(mutations.STORE_THEME, { loadError: true })
            return resolve(state.base.username)
          })
      })
    }
  },
  getters: {
    theme (state) {
      return state.theme
    },
    themes (state) {
      return state.themes
    },
    noPoints (state) {
      const x = state.theme && state.theme.excludes ? state.theme.excludes.split(',') : []
      return x.includes('rankings') || x.includes('points')
    },
    organizationName (state) {
      return state.theme && state.theme.name
    },
    logoUrl (state) {
      return state.theme && state.theme.logoUrl
    },
    getTheme: (state) => (username) => {
      return state.themes.find(f => {
        return f.username.toLowerCase() === username.toLowerCase()
      }) || null
    },
    color1IsDark: (state) => {
      return state.theme && isDark(state.theme.color1)
    },
    color2IsDark: (state) => {
      return state.theme && isDark(state.theme.color2)
    },
    darkColor: (state) => {
      if (!state.theme) return null
      return isDark(state.theme.color1) ? 'color1' : 'color2'
    },
    rankings (state, rootGetters) {
      const props = state.theme.props || []
      const s = [
        ...state.theme.rankings.map(m => {
          m.props = JSON.parse(m.jsonProps)
          m.route = { name: 'rankings', params: { id: m.id } }
          return m
        }),
        {
          id: 3,
          name: 'AAU',
          sb: 1,
          props: {
            logo: LogoUrls.aau.url
          },
          route: { name: 'rankings', params: { id: 3 } },
          hide: props.includes('no-aau-rankings') || rootGetters.sportId !== 1
        },
        {
          id: 33,
          name: 'USAV/AVP',
          sb: 1,
          props: {
            logo: LogoUrls.usavp.url,
            mw: true,
            adults: true,
            noState: true,
            showFilter: true
          },
          route: { name: 'rankings', params: { id: 33 } },
          hide: props.includes('no-avp-rankings') || rootGetters.sportId !== 1,
          // hide: true,
          domain: 'avp'
        },
        {
          id: 19,
          name: 'BVNE',
          sb: 1,
          props: {
            logo: LogoUrls.bvne.url
          },
          route: { name: 'rankings', params: { id: 19 } },
          hide: props.includes('no-bvne-rankings') || rootGetters.sportId !== 1
        },
        {
          id: 4,
          name: 'p1440',
          sb: 1,
          props: {
            logo: LogoUrls.p1440.url
          },
          route: { name: 'rankings', params: { id: 4 } },
          hide: props.includes('no-p1440-rankings') || rootGetters.sportId !== 1
        },
        {
          id: 33,
          name: 'USAV/AVP',
          sb: 1,
          props: {
            logo: LogoUrls.usavp.url,
            mw: true,
            adults: true,
            noState: true,
            showFilter: true
          },
          route: { name: 'rankings', params: { id: 33 } },
          hide: props.includes('no-usav-rankings') || rootGetters.sportId !== 1,
          domain: 'usav'
        }
      ].filter(f => !f.hide)
      if (rootGetters.subdomain.toLowerCase() === 'aau') return s.filter(f => f.id === 3)
      if (rootGetters.subdomain.toLowerCase() === 'avp') return s.filter(f => f.id === 33 && f.domain === 'avp')
      if (rootGetters.subdomain.toLowerCase() === 'bvne') return s.filter(f => f.id === 19)
      if (rootGetters.subdomain.toLowerCase() === 'p1440') return s.filter(f => f.id === 4)
      if (rootGetters.subdomain.toLowerCase() === 'gnbv') return s.filter(f => f.id === 19)
      if (rootGetters.subdomain.toLowerCase() === 'usav') return s.filter(f => f.id === 33 && f.domain === 'usav')
      return s
    },
    eventsTab (state) {
      return state.theme.username !== 'vbl' && state.theme.props && !state.theme.props.includes('tabs') ? 'current' : 'upcoming'
    },
    sportId (state) {
      const props = state.theme.props || []
      const p = props.find(f => f.startsWith('sportId:'))
      return p ? +p.replace('sportId:', '') : 1
    },
    bidStream (state, rootGetters) {
      const a = state.theme && state.theme.props.includes('bidStream')
      const b = state.theme && state.theme.props.includes('noBidStream')
      const c = rootGetters.user && rootGetters.user.props.includes('noBidStream')
      const d = rootGetters.user && rootGetters.user.pages && rootGetters.user.pages.length

      return a && !(b || c || d)
    }
  }
}
