import DtoUpdate from './DtoUpdate'
import max from 'lodash.max'
import CartItem from './CartItem'
import sum from 'lodash.sum'
import flatten from '../helpers/ArrayFlatten'
import uniq from 'lodash.uniq'
import { getAmountObj } from './HelperFunctions'

export default class Cart {
  constructor (dto) {
    this.items = []
    this.organization = null
    this.checking = false
    this.contact = null
    this.emailReceipt = null
    this.status = false
    this.processing = false
    this.promo = null
    this.stripeAccount = null
    this.noDelayedPayments = true
    this.paymentType = null
    this.allowPayLater = false

    if (dto) {
      this._update(dto)
    }
  }

  _update (dto) {
    if (typeof dto === 'string') dto = JSON.parse(dto)
    const exclude = ['items']
    DtoUpdate(dto, this, exclude)

    if (dto.items) {
      this.items = dto.items.map(i => new CartItem(i))
    }
  }

  update (item) {
    if (item.id === 0) this.addItem(item)
    const _item = item.id !== 0 ? this.items.find(f => f.id === item.id) : null
    if (_item) {
      _item.update(item)
    } else {
      this.addItem(item)
    }
  }

  addItem (item) {
    let id = 1
    if (this.items.length) {
      id = max(this.items.map(item => item.id)) + 1
    }
    item.id = id
    this.items.push(item)
  }

  removeItem (id) {
    const i = this.items.findIndex(f => f.id === id)
    if (i !== -1) {
      this.items.splice(i, 1)
    }
  }

  get total () {
    return sum(this.items.map(m => m.total))
  }

  get playerEmails () {
    return flatten(this.items.map(i => i.playerEmails))
  }

  get promos () {
    return uniq(this.items.filter(f => f.promo).map(i => i.promo.code))
  }

  get promoAmount () {
    return sum(this.items.filter(f => f.promo).map(i => i.promo.amount * i.quantity))
  }

  get hasRegistrations () {
    return this.items.find(f => f.type === 'TR')
  }

  get hasNonRegistrations () {
    return this.items.find(f => f.type !== 'TR')
  }

  get todayItems () {
    const pa = this.preAuthItems.map(m => m.id)
    return this.items.filter(f => !pa.includes(f.id))
  }

  get preAuthItems () {
    // return this.items.filter(f => f.preAuth || (f.registration && f.registration.waitlist))
    return this.noDelayedPayments ? this.items.filter(f => f.preAuth) : this.items.filter(f => f.preAuth || (f.registration && f.registration.waitlist))
  }

  get preAuthOnlyItems () {
    return this.items.filter(f => f.preAuth)
  }

  get preAuthAmount () {
    return sum(this.preAuthItems.map(m => m.total))
  }

  get preAuthMsg () {
    if (!this.preAuthItems.length) return null
    const item1 = this.preAuthItems[0]

    if (item1.status === 'AcceptanceOnly') return 'Teams will be charged when they are accepted into the event.'
    return this.preAuthOnlyItems[0] && this.preAuthOnlyItems[0].preAuth ? this.preAuthOnlyItems[0].preAuthMessage : 'waitlist'
  }

  get todaysTotal () {
    return this.total - this.preAuthAmount
  }

  get refundPolicy () {
    if (!this.organization || this.organization.id !== -3) return false
    return 'REFUND POLICY\n\tTeam self withdraws – Entry fee minus 5% admin fee.\n\tTeam self withdraws within seven days of tournament – NO REFUND.\n\tTournament cancelled due to any hazardous weather condition – NO REFUND. (hurricane, thunderstorm, lightning, high surf/tide)'
  }

  get feeTotal () {
    return sum(this.items.map(m => m.feeTotal))
  }

  get taxTotal () {
    return sum(this.items.map(m => m.taxTotal))
  }

  get amounts () {
    const obj = getAmountObj(this.items)
    obj.today = getAmountObj(this.todayItems)
    obj.later = getAmountObj(this.preAuthItems)
    return obj
  }

  get canPayLater () {
    if (this.allowPayLater) {
      if (this.hasNonRegistrations) {
        return false
      }
      return true
    }
    return false
  }

  get shippedProducts () {
    const prods = []
    this.carts.forEach(cart => {
      const p = cart.items.filter(f => f.type === 'PROD')
      if (p.length) prods.push(...p)
    })
    if (prods.length === 0) {
      return []
    }
    const needsShipping = prods.filter(f => {
      if (f.product) {
        var p = JSON.parse(f.product)
        if (p.shippingInfo && p.shippingInfo.delivery === 'ship') {
          return true
        }
      }
      return false
    })
    return needsShipping
  }

  get askForShipping () {
    return this.shippedProducts.length > 0
  }
}
