export default {
  aau: { url: 'https://vblimages.blob.core.windows.net/images/993446f8-6550-40f3-b137-3628e09e8795.jpg', alt: 'AAU' },
  avpnext: { url: 'https://vblimages.blob.core.windows.net/images/avpnext_icon.png', alt: 'AVPNext', class: 'avpNext' },
  avpfirst: { url: 'https://vblimages.blob.core.windows.net/images/avpfirstlogo.png', alt: 'AVPFirst' },
  avpamerica: { url: 'https://vblimages.blob.core.windows.net/images/AvpAmerica.png', alt: 'avpAmerica' },
  avpa: { url: 'https://vblimages.blob.core.windows.net/images/AvpAmerica.png', alt: 'avpAmerica' },
  ibvl: { url: 'https://vblimages.blob.core.windows.net/images/ibvl.webp', alt: 'IBVL' },
  isf: { url: 'https://vblimages.blob.core.windows.net/images/isf.png', alt: 'ISF' },
  p1440: { url: 'https://vblimages.blob.core.windows.net/images/edc63549-665a-4603-b1b5-2630755d0da6.png', alt: 'P1440' },
  p1440elite: { url: 'https://vblimages.blob.core.windows.net/images/p1440_logo.png', alt: 'P1440' },
  usav: { url: 'https://vblimages.blob.core.windows.net/images/361a61d2-a851-4c8d-8207-cb7f208b5f5a.png', alt: 'USAV' },
  usav2: { url: 'https://vblimages.blob.core.windows.net/images/4b15cac2-d691-45b5-9bfd-7f7ae0984482.png', alt: 'USAV' },
  bvne: { url: 'https://vblimages.blob.core.windows.net/images/BVNE-ParentBrandBY.png', alt: 'BVNE' },
  youtube: { url: 'https://vblimages.blob.core.windows.net/images/d8ecfebb-c1ea-44c3-99b3-7528fa88dc9c.png', alt: 'YouTube' },
  vimeo: { url: 'https://vblimages.blob.core.windows.net/images/297bddd5-7932-4224-9b6d-0a5f3c456b8b.png', alt: 'Vimeo' },
  tvt: { url: 'https://vblimages.blob.core.windows.net/images/ec86bd17-7ad5-4d2b-b0fb-86a20da05ffc.png', alt: 'TVT' },
  sbf: { url: 'https://vblimages.blob.core.windows.net/images/9683106a-55bf-4907-a556-a4c0cdd77de6.png', alt: 'SBF', color: 'black' },
  jva: { url: 'https://vblimages.blob.core.windows.net/images/3af7442a-82fd-47a0-9dbe-9d73e74ee876.jpg', alt: 'JVA' },
  uova: { url: 'https://vblimages.blob.core.windows.net/images/99d53822-5063-4001-86aa-4244fedb9bf6.png', alt: 'UOVA', color: '#333333' },
  volleyNation: { url: 'https://vblimages.blob.core.windows.net/images/d3e674f0-9b48-4179-ad90-76bef93ab3c2.gif', alt: 'Volley Nation' },
  txbva: { url: 'https://vblimages.blob.core.windows.net/images/6b4ec9b9-4990-4e67-97de-82b01f15b8c5.png', alt: 'TXBVA' },
  vollis: { url: 'https://vblimages.blob.core.windows.net/images/6d7ae95b-078a-433d-b4df-d90e0e3305d3.jpeg', alt: 'VOLLIS' },
  gav: { url: 'https://vblimages.blob.core.windows.net/images/d274b754-4734-4090-982c-ac4ee858bdd8.png', alt: 'GAV' },
  bvca: { url: 'https://vblimages.blob.core.windows.net/images/7f50988e-36ad-40a9-8370-34c685ee200b.jpg', alt: 'BVCA' },
  tva: { url: 'https://vblimages.blob.core.windows.net/images/265102ff-d341-4188-8a3b-eaea0ac33fe9.jpg', alt: 'TVA' },
  eevb: { url: 'https://vblimages.blob.core.windows.net/images/c93afc93-0ce9-4ead-8b15-01efe8883021.png', alt: 'EEVB' },
  votr: { url: 'https://vblimages.blob.core.windows.net/images/c5e476c1-fc89-475a-8041-29cfb01953cc.jpg', alt: 'VOTR' },
  xsv: { url: 'https://vblimages.blob.core.windows.net/images/8401f222-62d3-4e87-80a2-ecbb45527911.png', alt: 'XSV' },
  usavp: { url: 'https://vblimages.blob.core.windows.net/images/USAV_AVP.png', alt: 'USAV | AVP' }
}
