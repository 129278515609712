export default class StoreSDK {
  constructor (http) {
    this.http = http
  }

  get (username) {
    return this.http.get(`/store/${username || 'vbl'}`)
  }

  productGet (tag) {
    return this.http.get(`/store/product/${tag}`)
  }

  products = {
    getAll: (username) => {
      return this.http.get(`/store/${username || 'vbl'}/products`)
    },
    getList: (username) => {
      return this.http.get(`/store/${username || 'vbl'}/product-list`)
    },
    post: (username, dto) => {
      return this.http.post(`/store/${username || 'vbl'}/products`, dto)
    },
    disable: (username, ids) => {
      return this.http.post(`/store/${username || 'vbl'}/products/disable`, { ids: ids })
    },
    enable: (username, ids) => {
      return this.http.post(`/store/${username || 'vbl'}/products/enable`, { ids: ids })
    },
    delete: (username, ids) => {
      return this.http.post(`/store/${username || 'vbl'}/products/delete`, { ids: ids })
    },
    getUsage: (username, id) => {
      return this.http.get(`/store/${username || 'vbl'}/product/${id}/usage`)
    }
  }
}
